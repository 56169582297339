

<template>
  <v-toolbar>
        <v-toolbar-title class="text-grey" style="text-align: left;">
          Alunos
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
        class="HeaderPage-SearchInput margemDireita"
        :loading="loading"
        density="compact"
        variant="solo"
        label="Encontre um aluno"
        append-inner-icon="mdi-magnify"
        single-line
        hide-details
        @click:append-inner="onClick"
        @change="buscarPorNome($event)"
        v-model="encontrarAluno"
      ></v-text-field>
        <v-btn icon v-on:click="dialog = true; habiliatrModalidadesAlunoExistente = false; alunoExistente = true; snackMensagemAlunoExistente = ''">
          <v-icon>mdi-account-multiple-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>


  <div  class="App-Conteudo " id="App-Conteudo" style="margin-top: 20px;">
    <v-snackbar
      v-model="snackVisivel"
    >
      {{snackMensagem}}
      <template v-slot:actions>
        <v-btn
          color="pink"
          variant="text"
          @click="snackVisivel = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog
      v-model="dialog"
      width="800"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Adicionar aluno</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                >
                Pessoal
                <hr />
              </v-col>

              <v-col cols="12" v-if="alunoExistente">
                <v-text-field
                  label="Email *"
                  v-model="emailProcurar"
                  required
                  @change="verificarSeAlunoExiste"
                  
                ></v-text-field>
                  <v-alert
                    v-if="habiliatrModalidadesAlunoExistente"
                    type="warning"
                    title="Aluno já cadastrado"
                    :text="snackMensagemAlunoExistente"
                  ></v-alert>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                v-if="habiliatrModalidadesAlunoExistente"
              >
                <v-autocomplete
                  :items="materias"
                  v-model="NovasMateriasAluno"
                  label="Modalidades *"
                  item-title="nome"
                  item-value="id"
                  multiple
                  :disabled="!alunoExistente"
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                v-if="habiliatrModalidadesAlunoExistente"
              >
                <v-text-field
                  clearable
                  label="Nome completo"
                  v-model="InfosAluno.nome"
                  required
                  :disabled="alunoExistente"
                 
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="!alunoExistente">
                <v-text-field
                  label="Email *"
                  v-model="InfosAluno.email"
                  required
                  :disabled="true"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
                v-if="alunoExistente == false"
              >
              <div class="logoAcademiaPerfil" >
                <img v-bind:src="InfosAluno.foto" />
                <input type="file" @change="funcaoUploadImage" />
              </div>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
                v-if="alunoExistente == false"
              >
                <v-text-field
                  clearable
                  label="Nome completo *"
                  v-model="InfosAluno.nome"
                  required
                  :disabled="alunoExistente"
                 
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
                 v-if="alunoExistente == false"
              >
                <v-text-field
                  label="Data de nascimento *"
                  v-model="InfosAluno.dataNascimento"
                  hint=""
                  v-mask="'##/##/####'"
                  :disabled="alunoExistente"
                 
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
                v-if="alunoExistente == false"
              >
                <v-text-field
                  label="Celular *"
                  v-model="InfosAluno.celular"
                  hint=""
                  persistent-hint
                  required
                  v-mask="'(##) #####-####'"
                  :disabled="alunoExistente"
                  
                ></v-text-field>
              </v-col>
              <v-col cols="3" v-if="alunoExistente == false">
                <v-autocomplete
                  :items="sexo"
                  v-model="InfosAluno.sexo"
                  label="Sexo *"
                  :disabled="alunoExistente"
                  
                ></v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                v-if="alunoExistente == false"
              >
                <v-autocomplete
                  :items="materias"
                  v-model="InfosAluno.materias"
                  label="Modalidades *"
                  item-title="nome"
                  item-value="id"
                  multiple
                  :disabled="alunoExistente"
                  
                ></v-autocomplete>
              </v-col>
              <v-col 
                  cols="3" 
                  sm="3"
                  v-if="alunoExistente == false"
                >
                <v-autocomplete
                  :items="diasPagamento"
                  label="Dia de pagamento *"
                  v-model="InfosAluno.diaPagamento"
                  :disabled="alunoExistente"
                  
                ></v-autocomplete>
              </v-col>
              <v-col 
                  cols="12" 
                  sm="12"
                  v-if="alunoExistente == false"
                >
                <v-autocomplete
                  :items="statusAluno"
                  label="Status *"
                  v-model="InfosAluno.status"
                  item-title="nome"
                  item-value="id"
                  :disabled="alunoExistente"
                  
                ></v-autocomplete>
              </v-col>
              <v-col cols="12"
                sm="12"
                md="12"
                class="subtitleInputs"
                v-if="alunoExistente == false"
                >
                Endereço (opcional)
                <hr />
              </v-col>
              <v-col cols="6"  v-if="alunoExistente == false">
                <v-text-field
                  label="CEP"
                  v-model="InfosAluno.cep"
                  @change="procurarCEP($event)"
                  required
                  v-mask="'#####-###'"
                  :disabled="alunoExistente"
                 
                ></v-text-field>
              </v-col>

              <v-col cols="10" v-if="alunoExistente == false">
                <v-text-field
                  v-model="InfosAluno.rua"
                  label="Rua"
                  required
                  :disabled="alunoExistente"
                  
                ></v-text-field>
              </v-col>
              <v-col cols="2" v-if="alunoExistente == false">
                <v-text-field
                v-model="InfosAluno.numero"
                  label="Numero"
                  type="number"
                  id="campoNumero"
                  required
                  :disabled="alunoExistente"
                  
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="alunoExistente == false">
                <v-text-field
                v-model="InfosAluno.bairro"
                  label="Bairro"
                  required
                  :disabled="alunoExistente"
                  
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="alunoExistente == false">
                <v-text-field
                v-model="InfosAluno.cidade"
                  label="Cidade"
                  required
                  :disabled="alunoExistente"
                  
                ></v-text-field>
              </v-col>
              <v-col cols="6" v-if="alunoExistente == false">
                <v-text-field
                v-model="InfosAluno.estado"
                  label="Estado"
                  required
                  :disabled="alunoExistente"
                  
                ></v-text-field>
              </v-col>



            </v-row>
          </v-container>
          <small v-if="alunoExistente == false">*Preencha todos os campos marcados com *</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            v-if="alunoExistente == false"
            color="blue-darken-1"
            variant="text"
            @click="addAluno()"
          >
            Cadastrar
          </v-btn>
          <v-btn
            v-if="alunoExistente == true && habiliatrModalidadesAlunoExistente"
            color="blue-darken-1"
            variant="text"
            @click="addModalidadeAlunoExistente()"
          >
            Adicionar Modalidade
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="filtrosAluno">
      <v-chip class="click" v-bind:class="{ fundoclicado: this.filtroEmAtraso }" @click="setarVariaveisFiltro(0)">
            Em Atraso ({{ DataAlunos.filter(x => x.pagamentos.filter(y => y.status == 0).length > 0 && x.tipoAluno == 0).length }})
        </v-chip>&nbsp;
        <v-chip class="click" v-bind:class="{ fundoclicado: this.filtroRegular }" @click="setarVariaveisFiltro(2)" >
            Regulares  ({{ DataAlunos.filter(x => x.pagamentos.filter(y => y.status == 2).length > 0 && x.tipoAluno == 0).length }})
        </v-chip>
        <v-chip class="click" v-bind:class="{ fundoclicado: this.filtroAguardandoAprovacao }" @click="setarVariaveisFiltro(1)" >
            Aguardando Aprovação  ({{ DataAlunos.filter(x => x.pagamentos.filter(y => y.status == 1).length > 0).length }})
        </v-chip>

        <v-chip class="click" v-bind:class="{ fundoclicado: this.filtroAtivo }" @click="setarVariaveisFiltro('Ativo')" >
            Ativos  ({{ DataAlunos.filter(x => x.status.id == 1).length }})
        </v-chip>

        <v-chip class="click" v-bind:class="{ fundoclicado: this.filtroDesativado }" @click="setarVariaveisFiltro('Desativado')" >
            Desativados  ({{ DataAlunos.filter(x => x.status.id == 2 || x.status.id == 3).length }})
        </v-chip>

        <v-chip class="click" v-bind:class="{ fundoclicado: this.filtroGympass }" @click="setarVariaveisFiltro('Gympass')" >
            Gympass  ({{ DataAlunos.filter(x => x.tipoAluno == 1).length }})
        </v-chip>
        <v-chip class="click" v-bind:class="{ fundoclicado: this.filtroStaff }" @click="setarVariaveisFiltro('Staff')" >
            Staff  ({{ DataAlunos.filter(x => x.tipoAluno == 2).length }})
        </v-chip>
        <v-chip class="click" v-bind:class="{ fundoclicado: this.filtroTotalpass }" @click="setarVariaveisFiltro('Totalpass')" >
            Totalpass  ({{ DataAlunos.filter(x => x.tipoAluno == 3).length }})
        </v-chip>
        <br />
        <br />

        <v-chip v-for="item in materias" class="click" v-bind:class="{ fundoclicado: this.filtroMateriaId.includes(item.id) }" @click="setarVariaveisFiltro(item.id, true)">
          {{ item.nome }} 
        </v-chip>
    </div>
    
    <div class="App-Conteudo-Comp"> 
      <div>
      <span><b>Total:</b> {{DataAlunos.length}}</span>
  <v-table style="margin-bottom: 100px; padding: 20px" class="table table-striped table-bordered" id="tabelaDeAlunos">
    <thead>
      <tr>
        <th class="text-left">
          
        </th>
        <th class="text-left">
          Matricula
        </th>
        <th class="text-left" >
          Nome
        </th>
        <th class="text-left">
          Dia pagamento
        </th>
        <th class="text-left">
          Situação
        </th>
        <th class="text-left">
          Modalidades
        </th>
        <th class="text-left">
          Pagamentos
        </th>
        <th class="text-left">
          Editar
        </th>
      </tr>
    </thead>
    <tbody>
      <CardAluno v-for="(aluno, index) in DataAlunos.length" 
            :modalidadesProfessor="materias"
            :objetoAluno="DataAlunos[index]"
            :carregarAlunos="carregarAlunos"
            :Niveis="niveis"
          />
    </tbody>
  </v-table>

      </div>
    </div>
  </div>


</template>
  
<script>

import CardAluno from '../components/CardAluno.vue'
import {func} from '../assets/functions.js';
  export default {
    name: 'Alunos',
    components: {
        CardAluno
    },
    props: {
      msg: String
    },
    data() {
      return {
        alunoExistente: true,
        habiliatrModalidadesAlunoExistente: false,
        NovasMateriasAluno: null,
        emailProcurar: null,
        snackVisivel: null,
        snackMensagem: null,
        snackMensagemAlunoExistente: null,
        materias: [],
        niveis: [],
        diasPagamento: [],
        dialog: false,
        DataAlunosFull: [],
        DataAlunos: [],
        DataAlunosFilter: [],
        encontrarAluno: null,
        statusAluno: [],
        sexo: [],
        InfosAluno: {
            matricula: null,
            nome: null,
            dataNascimento: null,
            celular: null,
            email: null,
            sexo: null,
            materias: null,
            diaPagamento: null,
            cep: null,
            rua: null,
            numero: null,
            bairro: null,
            cidade: null,
            estado: null,
            foto: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png',
            status: null,
            //cpf: null
            responsavel: null
        },

        filtroEmAtraso: false,
        filtroRegular: false,
        filtroAguardandoAprovacao: false,

        filtroAtivo: false,
        filtroDesativado: false,
        filtroGympass: false,
        filtroStaff: false,
        filtroTotalpass: false,
        filtroMateriaId: [],

      }
    },
    created: function () {
        func.CarregarDados('professor/getMateriasProfessor').then(data => 
          this.carregarDadosCard(data.corpo)
        );
        func.CarregarDados('dominio/getNiveis').then(data => 
          this.niveis = data.corpo
        );
    },
    methods: {
      
      setarVariaveisFiltro(filtro, materias = false){
        if(filtro == 0){
          this.filtroEmAtraso = !this.filtroEmAtraso;
        }
        if(filtro == 2){
          this.filtroRegular = !this.filtroRegular;
        }
        if(filtro == 1){
          this.filtroAguardandoAprovacao = !this.filtroAguardandoAprovacao;
        }

        if(filtro == 'Ativo'){
          this.filtroAtivo = !this.filtroAtivo;
        }
        if(filtro == 'Desativado'){
          this.filtroDesativado = !this.filtroDesativado;
        }
        if(filtro == 'Gympass'){
          this.filtroGympass = !this.filtroGympass;
        }
        if(filtro == 'Staff'){
          this.filtroStaff = !this.filtroStaff;
        }
         if(filtro == 'Totalpass'){
          this.filtroTotalpass = !this.filtroTotalpass;
        }

        if(materias){
          if(this.filtroMateriaId.includes(filtro)){
            this.filtroMateriaId = this.filtroMateriaId.filter(x => x != filtro);
          }
          else{
            this.filtroMateriaId.push(filtro);
          }
        }
        this.filtrar();
      },
      filtrar(){
          this.DataAlunos = this.DataAlunosFull;
          //em atraso
          if(this.filtroEmAtraso){
            this.DataAlunos = this.DataAlunos.filter(x => x.pagamentos.filter(y => y.status == 0).length > 0 && x.tipoAluno == 0);
          }
          else{
            this.DataAlunos = this.DataAlunos.filter(x => x.pagamentos.length > 0);
          }

          //regular
          if(this.filtroRegular){
            this.DataAlunos = this.DataAlunos.filter(x => x.pagamentos.filter(y => y.status == 2).length > 0 && x.tipoAluno == 0);
          }
          else{
            this.DataAlunos = this.DataAlunos.filter(x => x.pagamentos.length > 0);
          }

          //Aguardando aprovação
          if(this.filtroAguardandoAprovacao){
            this.DataAlunos = this.DataAlunos.filter(x => x.pagamentos.filter(y => y.status == 1).length > 0);
          }
          else{
            this.DataAlunos = this.DataAlunos.filter(x => x.pagamentos.length > 0);
          }

          //ativo
          if(this.filtroAtivo){
            this.DataAlunos = this.DataAlunos.filter(x => x.status.id == 1);
          }
          else{
            this.DataAlunos = this.DataAlunos.filter(x => x.status.id != '');
          }

          if(this.filtroDesativado){
            this.DataAlunos = this.DataAlunos.filter(x => x.status.id == 2 || x.status.id == 3);
          }
          else{
            this.DataAlunos = this.DataAlunos.filter(x => x.status.id != '');
          }

          if(this.filtroMateriaId.length > 0){
            this.DataAlunos = this.DataAlunos.filter(x => x.materias.filter(y => this.filtroMateriaId.includes(y.id)).length > 0);
          }
          else{
            this.DataAlunos = this.DataAlunos.filter(x => x.materias.filter(y => y.id != '').length > 0);
          }
          
          if(this.filtroGympass){
            this.DataAlunos = this.DataAlunos.filter(x => x.tipoAluno == 1);
          }
          else{
            this.DataAlunos = this.DataAlunos;
          }

          if(this.filtroStaff){
            this.DataAlunos = this.DataAlunos.filter(x => x.tipoAluno == 2);
          }
          else{
            this.DataAlunos = this.DataAlunos;
          }
          if(this.filtroTotalpass){
            this.DataAlunos = this.DataAlunos.filter(x => x.tipoAluno == 3);
          }
          else{
            this.DataAlunos = this.DataAlunos;
          }
       
      },
      carregarAlunos(dados){
        //func.CarregarDados('professor/getMateriasProfessor').then(data => 
          this.carregarDadosCard(dados)
        //);
      },
      addAluno(){
        this.InfosAluno.matricula = 'Temporaria';
        var ignorar = ['foto', 'responsavel'];
        var erros = func.NovoVerificarJSONNull(this.InfosAluno, ignorar);
        if(erros.length > 0){
          this.snackMensagem = erros
          this.snackVisivel = true;
          return
        }
        this.InfosAluno.dataNascimento = func.formatarDataPadraoBD(this.InfosAluno.dataNascimento, false);
        this.InfosAluno.matricula = this.construirmatricula();

        func.resizedataURL(this.InfosAluno.foto).then(data => {
            this.InfosAluno.foto =  data;
            func.CarregarDados('alunos/addAluno', this.InfosAluno).then(data => 
              this.zerarVariaveisAluno()
            );
          }
        );
        this.dialog = false
       
      },
      zerarVariaveisAluno(){
        this.InfosAluno = {
            matricula: null,
            nome: null,
            dataNascimento: null,
            celular: null,
            email: null,
            sexo: null,
            materias: null,
            diaPagamento: null,
            cep: null,
            rua: null,
            numero: null,
            bairro: null,
            cidade: null,
            estado: null,
            foto: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png',
            status: null,
            responsavel: null
        },
        this.carregarAlunos()
      },
      construirmatricula(){
        const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = ' ';
        const charactersLength = 6;
        for ( let i = 0; i < charactersLength; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * 6));
        }
        return result.replace(/\s+/g, '');
      },
      funcaoUploadImage(e){
        func.onFileChange(e).then(data => this.InfosAluno.foto = data);
      },
      
      carregarDadosCard(dados){
        this.statusAluno = func.CarregarStatusAluno();
        if(dados != null && dados != undefined){
          this.converterMaterias(dados);
        }
        
        this.diasPagamento = func.CarregarDiasPagamento();
        this.sexo = func.CarregarSexos();
        
        func.CarregarDados('alunos/getAlunos').then(data => 
          this.preenchevariaveis(data)
        );

        this.filtroEmAtraso = false;
        this.filtroRegular = false;

        this.filtroAtivo = false;
        this.filtroDesativado = false;
        this.filtroAguardandoAprovacao = false;
      },
      preenchevariaveis(data){ 
        console.log(data)
        this.DataAlunosFull = data.corpo
        this.DataAlunos = data.corpo
        
      },
      converterMaterias(dados){
        this.materias = dados;
      },
      procurarCEP(event){
        func.CarregarCEP(this.InfosAluno.cep).then(data => this.carregarDadosCEP(data));
      },
      carregarDadosCEP(dados) {
        this.InfosAluno.rua = dados.logradouro
        this.InfosAluno.bairro = dados.bairro
        this.InfosAluno.cidade = dados.localidade
        this.InfosAluno.estado = dados.uf;
        document.getElementById("campoNumero").focus();
      },
      buscarPorNome(event) {
          this.DataAlunos = this.DataAlunosFull.filter(x => x.nome.toLowerCase().includes(this.encontrarAluno.toLowerCase()));
      },

      verificarSeAlunoExiste(){
        var post = {
          emailAluno: this.emailProcurar.toLowerCase()
        }
        func.CarregarDados('alunos/verificarSeAlunoExiste', post).then(data =>
          this.carregarFormDoAluno(data.corpo) 
        );
      },
      addModalidadeAlunoExistente(){
        var post = {
          matricula: this.InfosAluno.matricula,
          modalidade: this.NovasMateriasAluno
        }

        var erros = func.NovoVerificarJSONNull(post);
        if(erros.length > 0){
          this.snackMensagem = "Campo email e modalidade deve eestar preenchido "
          this.snackVisivel = true;
          return
        }

        func.CarregarDados('alunos/addMateriaNoAluno', post).then(data =>
          this.zerarVariaveisAluno()
        );
        this.dialog = false;
        this.habiliatrModalidadesAlunoExistente = false;
        this.alunoExistente = true;
        this.snackMensagemAlunoExistente = "";
      },
      carregarFormDoAluno(data){
        this.alunoExistente = true;
        //this.desabilitarCamposCadastroModalidades = true;
        this.InfosAluno = {
            matricula: null,
            nome: null,
            dataNascimento: null,
            celular: null,
            email: null,
            sexo: null,
            materias: null,
            diaPagamento: null,
            cep: null,
            rua: null,
            numero: null,
            bairro: null,
            cidade: null,
            estado: null,
            foto: 'https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Circle-icons-profile.svg/2048px-Circle-icons-profile.svg.png',
            status: null,
            responsavel: null
        }

        if(data.length > 0){
          //carregar dados
          this.snackMensagemAlunoExistente = "Aluno já existente na academia, adicione apenas a modalidade";
          var alunoSelecionado = data[0];
          this.InfosAluno.matricula = alunoSelecionado.matricula;
          this.InfosAluno.nome = alunoSelecionado.nome;
          this.InfosAluno.dataNascimento = func.formatarDataPadraoBR(alunoSelecionado.dataNascimento, false); 
          this.InfosAluno.celular = alunoSelecionado.celular;
          this.InfosAluno.email = alunoSelecionado.email;
          this.InfosAluno.sexo = alunoSelecionado.sexo;
          this.InfosAluno.materias = alunoSelecionado.materias;
          this.InfosAluno.diaPagamento = alunoSelecionado.diaPagamento;
          this.InfosAluno.cep = alunoSelecionado.cep;
          this.InfosAluno.rua = alunoSelecionado.rua;
          this.InfosAluno.numero = alunoSelecionado.numero;
          this.InfosAluno.bairro = alunoSelecionado.bairro;
          this.InfosAluno.cidade = alunoSelecionado.cidade;
          this.InfosAluno.estado = alunoSelecionado.estado;
          this.InfosAluno.foto =  alunoSelecionado.foto;
          this.InfosAluno.status = alunoSelecionado.status;
          this.InfosAluno.responsavel = alunoSelecionado.responsavel;
          this.habiliatrModalidadesAlunoExistente = true;
        }
        else{
          //inserir dados
          this.InfosAluno.email = this.emailProcurar;
          this.snackMensagemAlunoExistente = "";
          this.alunoExistente = false;
          this.habiliatrModalidadesAlunoExistente = false;
        }
      }
    }
  }

</script>
  

  