<template >
<div  id="carregandoPost"></div>
  <v-app id="conteudoFull">
    <v-main>
      <SideBar v-if="mostrarheader"></SideBar>
      <router-view/>
    </v-main>

<v-dialog
      v-model="dialogPagamento"
      persistent
      width="800"
    >
    
      <v-card>
        <v-card-title class="text-h5">
          Pagamento de mensalidade
        </v-card-title>
        <v-card-text>
           Pagamento de mensalidade não identificado, escolha uma opção abaixo para continuar utilizando todas as funcionalidades do app. <br />
           Se preferir você pode ativar a assinatura mensal para que seu plano seja cobrado automaticamente, fique tranquilo este é um serviço gerenciado totalmente pelo MercadoPago com segurança e praticidade <br />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="dialogPagamento = false"
          >
            Mais tarde
          </v-btn>
          
          <v-btn
            color="blue-darken-1"
            variant="outlined"
           v-on:click="pagar()"
          >
            PAGAR MENSALIDADE
          </v-btn>
           <v-btn
            color="blue-darken-1"
             variant="outlined"
           v-on:click="fazerAssinaturaMensal()"
          >
            FAZER ASSINATURA MENSAL
          </v-btn><br /><br />
        </v-card-actions>
        <v-card-text>
            <img width="200" src="./assets/images/mercadopago.png" class="mx-auto d-block"><br />
        </v-card-text>
      </v-card>
    </v-dialog>
  
    <v-footer class="footerGeral" v-if="mostrarheader">
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="link in links"
          :key="link"
          color="white"
          variant="text"
          class="mx-2"
          rounded="xl"
        >
          {{ link }}
        </v-btn>
        <v-col class="centralizarNoContent" cols="12">
          <div style="border: 0px solid black; width: 270px; margin: auto;">
            <div class="logo1" style="float: left;"></div>
            <div style="float: right;margin-top: 15px;">{{ new Date().getFullYear() }}@MestreDOJO</div>
            <div style="float: right;margin-top: 0px;">mestredojo@outlook.com</div>
          </div>
          
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import {func} from './assets/functions.js';
import './assets/main.css';
import SideBar from './components/SideBar.vue'
import Header from './components/Header.vue'

export default {
  name: 'App',
  components: {   
    SideBar,
    Header
  },
  data() {
    return {
      paginaAtual: "Alunos",
      mostrarheader: false,
      dialogCarregar: true,
      pagamentosFaltantes: [],
      dialogPagamento: false
    }
  },
  mounted: function () {
      var url = window.location.pathname;
      if(
          url.toLowerCase() == "/login" ||
          url.toLowerCase() == "/alunocheck" ||
          url.toLowerCase() == "/adicionarpagamento" ||
          url.toLowerCase() == "/catraca" || 
          url.toLowerCase() == "/criar" || 
          url.toLowerCase() == "/vercomprovante" || 
          url.toLowerCase() == "/sitemap" || 
          url.toLowerCase() == "/placarpublico" 
        ){
          
          this.mostrarheader = false
      }
      else{
        this.verificarPagamentos()
        this.mostrarheader = true;
        if(url == "/" && localStorage.getItem('DojoMasterToken') != null){
          window.location.replace("/estatisticas");
        }
        if(url == "/" && localStorage.getItem('DojoMasterToken') == null){
          window.location.replace("/login");
        }


      }
    },
    methods: {
      fazerAssinaturaMensal(){
        window.open('https://www.mercadopago.com.br/subscriptions/checkout?preapproval_plan_id=2c9380848932ec980189388aef8f01cb', '_blank');
      },
      verificarPagamentos(){
          func.CarregarDados('academias/verificarPagamento').then(data =>
            this.construirDialog(data.corpo) 
            
          );
      },
      construirDialog(data){
        console.log(data)
        this.pagamentosFaltantes = data;
        if(data.length > 0){
          this.dialogPagamento = true
        }
        else{
          this.dialogPagamento = false
        }
      },
      pagar(){ 
        var post = {
          dataReferencia:  this.pagamentosFaltantes[0].split('T')[0]
        }
        
        func.CarregarDados('jobs/create_preference', post).then(data => 
          this.irPaginaPagamento(data)
        );
      },
      async irPaginaPagamento(data){
        window.location.assign(data.id.init_point);
      },
    }
}
</script>
